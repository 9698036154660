import React from 'react';

// eslint-disable-next-line no-restricted-imports
import { Alert, AlertProps, AlertTitle, AlertTitleProps } from '@mui/material';

//
//

type AppAlertProps = {
  title?: string | React.ReactNode;
  titleProps?: AlertTitleProps;
  content?: React.ReactNode;
} & Omit<AlertProps, 'variant'>;

export const AppAlert = ({ title, titleProps, content, children, ...props }: AppAlertProps) => {
  const hasDetails = content || React.Children.toArray(children)?.length > 0;

  return (
    <Alert {...props} variant="standard">
      {title && (
        <AlertTitle mb={hasDetails ? undefined : 0} mt={hasDetails ? undefined : 0} {...titleProps}>
          {title}
        </AlertTitle>
      )}
      {content || children}
    </Alert>
  );
};
